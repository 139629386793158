import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, layout } from 'styled-system'
import { Card } from './Card'
import { keyframes } from 'styled-components'

const wave = keyframes`
  0% { background-position: -500px 0; }
  100% { background-position: 500px 0; }
`
const wave2 = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`
const Component = styled.div`
  height: 200px;
  overflow: hidden;
  ${space}
  ${layout}
  
  &:not(:first-child) {
    margin-top: 20px; 
  }

  & .loading,
    .loading2 {
    background-repeat: no-repeat;
    border-radius: ${props => props.theme.borderRadius};
    background-size: px 104px;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    infinite;
    background-color: ${props => props.theme.color.brandPrimaryTint4};
    background-image: 
      linear-gradient( 90deg, 
      ${props => props.theme.color.brandPrimaryTint4}, 
      ${props => props.theme.color.brandPrimaryTint5}, 
      ${props => props.theme.color.brandPrimaryTint4});

    &:not(:first-child) {
      margin-top: 20px; 
    }
  }
 
  & .loading { animation-name: ${wave}; 
    height: calc(${props => props.theme.baseUnit} * 12);
    width: 25%
  }
  & .loading2 {  animation-name: ${wave2};
  height: calc(${props => props.theme.baseUnit} * 6);
}

}
`

export const CardLoading = ({ ...rest }) => (
  <Card>
    <Component {...rest}>
      <div className="loading" />
      <div className="loading2" />
      <div className="loading2" />
    </Component>
  </Card>
)

CardLoading.propTypes = {
  children: PropTypes.node,
  space: PropTypes.string,
  layout: PropTypes.string
}

export default CardLoading
