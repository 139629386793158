import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Card } from '../js/components/Card';
import { CardLoading } from '../js/components/CardLoading';
import { Props, Playground } from 'docz';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "cardloading"
    }}>{`CardLoading`}</h1>
    <p><inlineCode parentName="p">{`import { CardLoading } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`The Loading Card is used to when loading content of an unknown amount of time. You should set the height to match the rendered content on desktop to avoid content around being pushed around as components render.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={CardLoading} mdxType="Props" />
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'<ThemeProvider theme={themeConWeb}>\n  <CardLoading height={400} mb={16}></CardLoading>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Card,
      CardLoading,
      Props,
      Playground,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
    <CardLoading height={400} mb={16} mdxType="CardLoading">
    </CardLoading>
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      